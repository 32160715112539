import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import CountUp from "react-countup";
import aboutusPageMapping from "../../../mappings/aboutusPageMapping";
import { useSelector } from "react-redux";
import { getcomponents } from "../../Alldata";

const Counter = () => {
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);
  const [aboutusPageData, setAboutusPageData] = useState({});
  const [counterSectionData, setCounterSectionData] = useState({});
  const { id, numbers } = aboutusPageMapping;

  useEffect(() => {
    const aboutData = pagesData.find((item) => item._id === id);
    if (aboutData) {
      setAboutusPageData(aboutData);
    }
  }, [pagesData]);

  useEffect(() => {
    const fetchCouter = async () => {
      if (aboutusPageData.componentData) {
        const counterCollectionId = aboutusPageData.componentData.find(
          (item) => item.id === numbers.id
        )._id;

        if (counterCollectionId) {
          try {
            const res = await getcomponents("", counterCollectionId);
            const counters = res[0]?.map((item) => ({
              countTitle: item.componentData[0].value || "",
              count: item.componentData[1].value || 0,
            })) || [];

            setCounterSectionData({
              counters
            })
          } catch (error) {
            console.error("Error fetching counters");
          }
        }
      }
    };

    fetchCouter();
  }, [aboutusPageData]);

  return (
    <React.Fragment>
      <section className="section bg-light">
        <Container>
          <Row>
            <Col lg={3} md={6}>
              <div className="counter-box mt-3">
                <div className="counters counter_custom text-center">
                  <CountUp
                    end={counterSectionData.counters?.[3]?.count}
                    duration={1}
                    className="counter mb-0"
                  ></CountUp>
                  <h6 className="fs-16 mt-3 ">{counterSectionData.counters?.[3]?.countTitle}</h6>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="counter-box mt-3">
                <div className="counters counter_custom text-center">
                  <CountUp
                    end={counterSectionData.counters?.[2]?.count}
                    duration={1}
                    className="counter mb-0"
                  ></CountUp>
                  <h6 className="fs-16 mt-3">{counterSectionData.counters?.[2]?.countTitle}</h6>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="counter-box mt-3">
                <div className="counters counter_custom text-center">
                  <CountUp
                    end={counterSectionData.counters?.[1]?.count}
                    decimals={2}
                    duration={1}
                    className="counter mb-0"
                  ></CountUp>
                  <h6 className="fs-16 mt-3">{counterSectionData.counters?.[1]?.countTitle}</h6>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="counter-box mt-3">
                <div className="counters counter_custom text-center">
                  <CountUp
                    end={counterSectionData.counters?.[0]?.count}
                    duration={1}
                    className="counter mb-0"
                  ></CountUp>
                  <h6 className="fs-16 mt-3">{counterSectionData.counters?.[0]?.countTitle}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Counter;
