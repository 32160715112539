const homePageMapping = {
  id: "673193287128df65b0442da0",

  FindYourDreamJobs: {
    id: "6731937f7128df65b0442da1",
  },

  BrowseJobsCategories1: {
    id: "6719daea10c82363045c7264",
    title: {
      id: "b736237f-d064-4aa7-a8cb-9b49a39f47f3",
    },
    description: {
      id: "f887bf0f-e4b5-4e3a-9c8c-d0132fad42ba",
    },
  },

  BrowseJobsCategories: {
    id: "67187c9a93b5f244673f47e6",
    BrowseJobsCategories2: {
      id: "67187c3193b5f244673f47e5",
      // icon: {
      //     id: "9c5a6546-e003-4647-8c74-937241ff3fde",
      // },
      // name: {
      //     id: "d11add08-0a81-4a2a-8fd0-1b420aa91a1f",
      // },
      // Number: {
      //     id: "70b3c74d-e1bd-4396-893a-137878122618",
      // },
    },
  },

  HowItWork1: {
    id: "67187a1493b5f244673f47e1",
    title: {
      id: "65219597-c40b-4141-95fd-1111a6e16e63",
    },
    description: {
      id: "6ea9ca8c-9985-4e32-93c1-5e66296a6da8",
    },
  },

  HowItWork: {
    id: "67187ae393b5f244673f47e2",
    HowItWork2: {
      id: "6719ebaf10c82363045c726f",
    },
  },

  HappyCandidates1: {
    id: "671a0a7c10c82363045c7275",
    title: {
      id: "cebcab78-e77e-4289-910e-58b36c084603",
    },
    description: {
      id: "daf9697b-84d9-4476-81fe-c26eabc62fc6",
    },
  },

  HappyCandidates: {
    id: "6718763493b5f244673f47dc",
    HappyCandidates2: {
      id: "671878b493b5f244673f47df",
    },
  },

  BrowseOur5000LatestJobs: {
    id: "67187ff993b5f244673f47e7",
    BrowseOur: {
      id: "c6eaf539-0e1a-47fa-a772-776c92c797d2",
    },
    5000: {
      id: "71412b25-c9d0-4c29-8667-a2b4c188ab0d",
    },
    latestJobs: {
      id: "9fc0a0ce-a300-4e9d-91ea-a1fddf3e403b",
    },
    description: {
      id: "10656f04-2181-4c99-ae37-fe953ed2aa20",
    },
  },

  QuickCareerTips1: {
    id: "671b472f59d17c630608a7e3",
    title: {
      id: "4cfca385-731a-4f4f-80ea-6ec586bc1b7b",
    },
    description: {
      id: "2f372624-157d-4e1c-90f8-5a2df7078bb7",
    },
  },
};

export default homePageMapping;
