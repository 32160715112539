import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import aboutusPageMapping from "../../../mappings/aboutusPageMapping";

const Section = () => {
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);
  const {breadcrumb, id} = aboutusPageMapping;

  const [aboutusPageData, setAboutusPageData] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState({
    breadcrumb1: "",
    breadcrumb2: "",
    breadcrumb3: "",
  });


  useEffect(() => {
    const aboutData = pagesData.find((item) => item._id === id);

    if (aboutData) {
      setAboutusPageData(aboutData);
    }
  }, [pagesData]);

  useEffect(() => {
    if (aboutusPageData.componentData) {
      const breadcrumbData = aboutusPageData.componentData.find(
        (item) => item.id === breadcrumb.id
      );

      const breadcrumb1Data = breadcrumbData.childComponents?.find(
        (item) => item.id === breadcrumb.breadcrumb1.id
      );

      const breadcrumb2Data = breadcrumbData.childComponents?.find(
        (item) => item.id === breadcrumb.breadcrumb2.id
      );

      const breadcrumb3Data = breadcrumbData.childComponents?.find(
        (item) => item.id === breadcrumb.breadcrumb3.id
      );

      setBreadcrumbs({
        breadcrumb1: breadcrumb1Data?.value || "",
        breadcrumb2: breadcrumb2Data?.value || "",
        breadcrumb3: breadcrumb3Data?.value || "",
      });
    }
  }, [aboutusPageData]);

  return (
    <React.Fragment>
      <section className="page-title-box">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="text-center text-white">
                <h3 className="mb-4">About Us</h3>
                <div className="page-next">
                  <nav
                    className="d-inline-block"
                    aria-label="breadcrumb text-center"
                  >
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <Link to="/">{breadcrumbs.breadcrumb1}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">{breadcrumbs.breadcrumb2}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {breadcrumbs.breadcrumb3}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="position-relative">
        <div className="shape">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250">
            <path
              fill="#FFFFFF"
              fillOpacity="1"
              d="M0,192L120,202.7C240,213,480,235,720,234.7C960,235,1200,213,1320,202.7L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
            ></path>
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Section;
