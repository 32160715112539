import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../Alldata";
import { getcomponents } from "../Alldata";
import homePageMapping from "../../mappings/homePageMapping";
import {
  Col,
  Row,
  Container,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

//Process Images Import
import processImage1 from "../../assets/images/process-01.png";
import processImage2 from "../../assets/images/process-02.png";
import processImage3 from "../../assets/images/process-03.png";

const HowItWorks = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [components, setComponents] = useState([]);
  const steps =
    components[0]?.map((item) => item.componentData[0]?.childComponents) || [];

  // 2. fetch the particular section data
  const { filteredrecentData, name } = useContext(UserContext);

  const myObject =
  Array.isArray(filteredrecentData) &&
  filteredrecentData.find((item) => item._id === homePageMapping.id)?.componentData;


  const title = Array.isArray(myObject) &&
  myObject.find((item) => item._id === homePageMapping.HowItWork1.id)?.childComponents.find((item) => item.id === homePageMapping.HowItWork1.title.id)?.value

  const description = Array.isArray(myObject) &&
  myObject.find((item) => item._id === homePageMapping.HowItWork1.id)?.childComponents.find((item) => item.id === homePageMapping.HowItWork1.description.id)?.value  

  const idtosent =
    (Array.isArray(filteredrecentData) &&
      filteredrecentData.find((item) => item._id === homePageMapping.HowItWork.id)?._id);


  useEffect(() => {
    const token = localStorage.getItem("token");
    getcomponents(token, idtosent).then(setComponents);
  }, [filteredrecentData]);

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="section-title me-5">
                {/* <h3 className="title">How It Work</h3> */}
                <h3 className="title">{title}</h3>
                <p className="text-muted">
                  {/* Post a job to tell us about your project. We'll quickly match
                  you with the right freelancers. */}
                  {description}
                </p>
                <Nav className="process-menu flex-column nav-pills">
                  {steps.slice(0, 3).reverse().map((step, index) => (
                    <NavLink
                      key={index}
                      to="#"
                      className={classnames({
                        active: activeTab === String(index + 1),
                      })}
                      onClick={() => tabChange(String(index + 1))}
                      type="button"
                    >
                      <div className="d-flex">
                        <div className="number flex-shrink-0">{index + 1}</div>
                        <div className="flex-grow-1 text-start ms-3">
                          <h5 className="fs-18">
                            {step[0]?.value || "unable to fetch"}
                          </h5>
                          <p className="text-muted mb-0">
                            {step[1]?.value || "unable to fetch"}
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  ))}
                </Nav>
              </div>
            </Col>
            <Col lg={6}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <img src={processImage1} alt="" className="img-fluid" />
                </TabPane>
                <TabPane tabId="2">
                  <img src={processImage2} alt="" className="img-fluid" />
                </TabPane>
                <TabPane tabId="3">
                  <img src={processImage3} alt="" className="img-fluid" />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default HowItWorks;
