import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Icon } from "@iconify/react";
import aboutusPageMapping from "../../../mappings/aboutusPageMapping";
import { useSelector } from "react-redux";
import { getcomponents } from "../../Alldata";

const Features = () => {
  const features = [
    {
      id: 1,
      featureIcon: "uim-object-ungroup",
      featureName: "Manage Job Ads",
    },
    {
      id: 2,
      featureIcon: "uim-telegram-alt",
      featureName: "Create CV",
    },
    {
      id: 3,
      featureIcon: "uim-lock-access",
      featureName: "Privacy Policy",
    },
    {
      id: 4,
      featureIcon: "uim-user-md",
      featureName: "Recruiter Profiles",
    },
    {
      id: 5,
      featureIcon: "uim-airplay",
      featureName: "Display Jobs",
    },
    {
      id: 6,
      featureIcon: "uim-rocket",
      featureName: "For Agencies",
    },
    {
      id: 7,
      featureIcon: "uim-history",
      featureName: "Quick Support",
    },
    {
      id: 8,
      featureIcon: "uim-bookmark",
      featureName: "Bookmark Jobs",
    },
    {
      id: 9,
      featureIcon: "uim-graph-bar",
      featureName: "Real-time Analytics",
    },
  ];

  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);
  const [keyFeatureSectionData, setKeyFeatureSectionData] = useState({});
  const { keyFeatures, id } = aboutusPageMapping;
  const [aboutusPageData, setAboutusPageData] = useState({});

  useEffect(() => {
    const aboutData = pagesData.find((item) => item._id === id);
    if (aboutData) {
      setAboutusPageData(aboutData);
    }
  }, [pagesData]);

  useEffect(() => {
    const fetchFeatures = async () => {
      if (aboutusPageData.componentData) {
        const keyFeat = aboutusPageData.componentData.find(
          (item) => item.id === keyFeatures.id
        );

        const keyFeatLabel = keyFeat?.childComponents?.find(
          (item) => item.id === keyFeatures.keyFeatures.id
        );

        const keyFeatTitle = keyFeat?.childComponents?.find(
          (item) => item.id === keyFeatures["title-1"].id
        );

        const featuresCollectionId = keyFeat?.childComponents?.find(
          (item) => item.id === keyFeatures.keyFeaturesName.id
        )?._id;

        if (featuresCollectionId) {
          try {
            const res = await getcomponents("dsa", featuresCollectionId);
            const features =
              res[0].map((item) => ({
                featureTitle: item?.componentData[0]?.value || "",
                featureImg: item?.componentData[1]?.value || "",
              })) || [];

            setKeyFeatureSectionData({
              keyFeatureLabel: keyFeatLabel?.value || "",
              keyFeatTitle: keyFeatTitle?.value || "",
              features,
            });
          } catch (error) {
            console.error("Error fetching features:", error);
          }
        }
      }
    };

    fetchFeatures();
  }, [aboutusPageData]);

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="section-title text-center mb-5">
                <h3 className="title mb-4">
                  {keyFeatureSectionData.keyFeatureLabel}
                </h3>
                <p className="para-desc text-muted mx-auto">
                  {keyFeatureSectionData.keyFeatTitle}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            {keyFeatureSectionData?.features?.map((feature, index) => (
              <Col lg={4} md={6} className="mt-4 pt-2" key={index}>
                <div className="about-feature p-3 d-flex align-items-center rounded-3">
                  <div className="featrue-icon flex-shrink-0">
                    <Icon
                      icon={features[index].featureIcon}
                      className="text-primary"
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="fs-18 mb-0">{feature.featureTitle}</h6>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
