import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import JobDetailsDescription from "./JobDetailsDescription";
import JobVacancyPost from "./JobVacancyPost";
import RightSideContent from "./RightSideContent";
import Section from "./Section";
import { useParams } from "react-router-dom";
import magicJson from "../../../magicJson/magicJson";
import ApiRequest from "../../../utils/apiRequest";

const apiRequest = new ApiRequest();

const JobDetails = () => {
  document.title = "Job Details | Jobcy";

  const [job, setJob] = useState({});
  const { id } = useParams();

  useEffect(() => {
    async function getJobById() {
      magicJson.endpoint = "jobs";
      magicJson.executor = "getJobsById";
      magicJson.data = [{ id }];

      const resp = await apiRequest.sendRequest(magicJson);
      setJob(resp?.data[0][0]);
    }

    getJobById();
  }, [id]);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            <Col lg={8}>
              <JobDetailsDescription job={job} />
              <JobVacancyPost />
            </Col>
            <Col lg={4} className="mt-4 mt-lg-0">
              <RightSideContent job={job} />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobDetails;
