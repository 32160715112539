import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import aboutusPageMapping from "../../../mappings/aboutusPageMapping";

const Cta = () => {
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);
  const [aboutusPageData, setAboutusPageData] = useState({});
  const [ctaSectionData, setCtaSectionData] = useState({});
  const { id, freeTrial } = aboutusPageMapping;

  useEffect(() => {
    const aboutData = pagesData.find((item) => item._id === id);
    if (aboutData) {
      setAboutusPageData(aboutData);
    }
  }, [pagesData]);

  useEffect(() => {
    if (aboutusPageData.componentData) {
      const cta = aboutusPageData.componentData.find(
        (item) => item.id === freeTrial.id
      );

      const ctaTitle = cta.childComponents?.find(
        (item) => item.id === freeTrial.titleName.id
      );

      const ctaDescription = cta.childComponents?.find(
        (item) => item.id === freeTrial.description.id
      );

      const getStartedLink = cta.childComponents?.find(
        (item) => item.id === freeTrial.link1.id
      )

      const freeTrialLink = cta.childComponents?.find(
        (item) => item.id === freeTrial.link2.id
      );

      setCtaSectionData({
        ctaTitle: ctaTitle.value || "",
        ctaDescription: ctaDescription.value || "",
        getStartedLink: {
          linkName: getStartedLink.childComponents[0].value || "",
          linkUrl: getStartedLink.childComponents[1].value || "",
          linkLogo: getStartedLink.childComponents[2].value || "",
        },
        freeTrialLink: {
          linkName: freeTrialLink.childComponents[0].value || "",
          linkUrl: freeTrialLink.childComponents[1].value || "",
          linkLogo: freeTrialLink.childComponents[2].value || "",
        },
      });
    }
  }, [aboutusPageData]);

  const handleClick = () => {
    toast.remove();
    toast.error("Work in progress!");
  };

  return (
    <React.Fragment>
      <section className="section bg-light">
        <Container>
          <Row className="justify-content-center">
            <div className="section-title text-center">
              <h3 className="title mb-4 pb-2">
                {ctaSectionData.ctaTitle}
              </h3>
              <p className="para-desc text-muted mx-auto">
                {ctaSectionData.ctaDescription}
              </p>
              <div className="mt-4">
                <Link
                  to="#"
                  className="btn btn-primary btn-hover mt-2"
                  onClick={handleClick}
                >
                  <i className="uil uil-rocket me-1"></i> {ctaSectionData.getStartedLink?.linkName}
                </Link>
                <Link
                  to="#"
                  className="btn btn-outline-primary btn-hover ms-sm-1 mt-2"
                  onClick={handleClick}
                >
                  <i className="uil uil-capsule me-1"></i> {ctaSectionData.freeTrialLink?.linkName}
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Cta;
